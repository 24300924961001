/* .form-control:disabled, .form-control[readonly]{
  cursor: no-drop;
  background-color: #e9ecef;
} */

textarea.form-control{
  min-height: auto!important;
}

.order-title{
  font-size: 20px;
  margin-top: 2%;
  color: #2a1d42;
}

.custom-nxt-btn{
  padding: 7px;
  min-width: 100px;
}

.custom-order-btn{
  width: 100%;
  text-align: right;
  /* padding-right: 15px; */
  /* padding-left: 15px; */
}

.checkout-summary{
  margin-top: 18%;
  padding: 1.4rem 1.8rem 0;
  border: 1px solid #ddd;
  background: #fbfbfb;
}

.cart-items{
  margin-bottom: 2%;
}

.m-l-5{
  margin-left: 5px;
}

.custom-address-add{
  float: right;
  min-width: auto;
  font-size: 12px;
  cursor: pointer;
}

.custom-address-btn{
  float: right;
  padding: 5px 10px;
  min-width: auto;
  font-size: 12px;
  background-color: #31B0D5;
  border: none;
}

.custom-address-btn:hover{
  background-color: #31B0D5;
  border: none;
}

.custom-user-address{
  background: #ffffff;
  padding: 10px;
  margin-bottom: 5px;
}

.custom-user-address p{
  /* font-size: 14px; */
  margin-bottom: 0;
  cursor: pointer;
}

.custom-add-address{
  padding: 10px 10px 10px 15px;
}

.custom-radio{
  vertical-align: top;
  margin-top: 3px;
}

.bt-line{
  border-top: 1px solid #ccc!important;
}

.pad-1-6 td{
  padding-top: 1rem!important;
}

/* .pad-1-6:last-child td{
  padding-top: 1.6rem!important;
} */

.table.table-totals tbody tr:first-child td {
  padding-top: 0.6rem;
}

.no-mb{
  margin-bottom: 0!important;
}

.no-mar{
  margin: 0;
}

.notes{
  margin-bottom: 0;
    background: #5BC0DE;
    color: #ffffff;
    padding: 5px 15px;
    text-align: center;
    border-bottom-left-radius: 10px;
}

.delivery-time{
  font-size: 13px;
  font-weight: bold;
  color: #59a545;
}

.placed-order-style{
  /* position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  margin-top: 3%;
  text-align: center;
  width: 100%;
}

.placed-order-btn{  
	padding: 10px 11px;
  font-size: 14px;
  cursor: pointer;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border: 1px solid #2a1d42;
  background: #ffffff;
  color: #2a1d42;
}
.placed-order-btn:hover{
  border: 1px solid #2a1d42;
  background: #2a1d42;
  color: #ffffff;
}

.placed-order-img{
  width: 350px;
  height: auto;
  display: unset;
}

.placed-order-text{
  font-size: 24px;
  background: -webkit-linear-gradient(left top, right top, from(#96c93e), to(#2a1d42));
  background: linear-gradient(to right, #96c93e 0%, #2a1d42 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media(max-width:500px){
  .checkout-summary{
    margin-top:0%;
    margin-bottom: 5%;
  }
  .res-checkout-img{
    width: 300px!important;
    height: auto!important;
  }
  .res-mtb-5{
    margin: 5% 0;
  }
}

.hPEdlB{
  z-index: 9999;
}
.order_list_cuss_stylee
{
  width: 21px !important;
  display: inline;
  margin-right: 13px;
}
.displayname_justify
{
  text-align: justify;
}
.displayname_justify p
{
  color: #2a1d42;
}